<template>
    <div class="menu-wrapper" :class="{ 'layout-sidebar-active': sidebarActive }" @click="onMenuClick"
        @mouseover="onSidebarMouseOver" @mouseleave="onSidebarMouseLeave">
        <div class="sidebar-logo">
            <router-link to="/">
                <img src="https://dash.sorte.tv/img/logo-sorte-nova.png" alt="logo-bingo" style="width: 25px; height: 20px;">
            </router-link>
            <a class="sidebar-pin" @click="onToggleMenu($event)">
                <span class="pin"></span>
            </a>
        </div>

        <div class="layout-menu-container">
            <AppSubmenu class="layout-menu" :items="filtrarMenu" :layoutMode="layoutMode" :parentMenuItemActive="true"
                :menuActive="menuActive" :mobileMenuActive="mobileMenuActive" :root="true"
                @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />
        </div>
    </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import AppSubmenu from "./AppSubmenu"

export default {
    name: "AppMenu",
    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "toggle-menu", "sidebar-mouse-over", "sidebar-mouse-leave"],
    props: {
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean
    },
    
    components: { AppSubmenu },

    setup() {
        const store = useStore()
        const isAdmin = computed(() => store.getters.isAdmin)

        return { isAdmin}
    },

    data() {

        return {
            baseMenu: [
                { label: "Dashboard", icon: "pi pi-home", to: "/" },
                { 
                    label: "Relatórios", icon: "pi pi-file-edit",
                    items:[
                        {label: "Recolhe", icon:"pi pi-dollar", to: "/relatorios/recolhe"},
                        {label: "Posição do Recolhe", icon: "pi pi-dollar", to: "/relatorios/posicaorecolhe"},
                        {label: "Ganhadores", icon: "pi pi-star", to: "/relatorios/ganhadores"},
                        {label: "Por Seção", icon: "pi pi-map", to: "/relatorios/secao"},
                        {label: "Por Estabelecimento", icon: "pi pi-building", to: "/relatorios/estabelecimento"},
                    ]
                },
                {
                    label: "Usuários", icon: "pi pi-users",
                    items: [
                        { label: "Gestores", icon: "pi pi-user", to: "/usuarios/gestores" },
                        { label: "Operadores", icon: "pi pi-user", to: "/usuarios/operadores" },
                        { label: "Estabelecimentos", icon: "pi pi-building", to: "/estabelecimentos" },
                    ],
                },
                { label: "Seções", icon: "pi pi-map", to: "/secoes" },
                { label: "Rotas", icon: "pi pi-directions-alt", to: "/rotas" },
                { label: "TV", icon: "pi pi-desktop", to: "/usuarios/tv" },
                
            ]
        }
    },

    computed: {
        filtrarMenu() {
            let menu = [...this.baseMenu]

            if (this.isAdmin) {
                menu.splice(2, 0, { label: "Regiões", icon: "pi pi-map-marker", to: "/regioes" })

                let usuariosItem = menu.find(item => item.label === "Usuários")
                if (usuariosItem) {
                    usuariosItem.items.splice(0, 0, { label: "Admins", icon: "pi pi-user", to: "/usuarios/admins" })
                }

                let relatoriosItem = menu.find(item => item.label === "Relatórios")
                if(relatoriosItem){
                    relatoriosItem.items.splice(3,0, {label: "Por Sorteio", icon:"pi pi-globe", to: "/relatorios/sorteio"},)
                }
            }
            else{
                menu.splice(1,0, { label: "Partidas", icon: "pi pi-flag", to: "/partidas" },)
            }

            return menu
        }
    },

    methods: {
        onSidebarMouseOver() {
            if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
                this.$emit("sidebar-mouse-over")
            }
        },
        onSidebarMouseLeave() {
            if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
                setTimeout(() => {
                    this.$emit("sidebar-mouse-leave")
                }, 250)
            }
        },
        onToggleMenu(event) {
            this.$emit("toggle-menu", event)
        },
        onMenuClick(event) {
            this.$emit("menu-click", event)
        },
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event)
        },
        onRootMenuItemClick(event) {
            this.$emit("root-menuitem-click", event)
        }
    },
}
</script>
