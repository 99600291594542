export default (api, resource) => ({
    
    create: async ({user, token}) => {
        let url = `${resource}/registrar`
        let authorization = token

        if(user.funcao === "ADMIN"){
            url = `${url}/administrador`
            authorization = "11223344"
        }

        if(user.conta && user.conta.funcao === "OPERADOR"){
            url = `${url}/operador`
        }

        if(user.funcao === "TV"){
            url = `${url}/tv`
        }

        if(user.conta && user.conta.funcao === "POS"){
            url = `${url}/pos`
        }

        const response = await api.post(url,{
            ...user
          }, {
            headers: {
                Authorization: authorization
            } 
        })
    
        return {
            data: response.data
        }
    },

    
    login: async ({login, password }) => {

        const response = await api.post(`${resource}/entrar`, {
            login,
            senha: password
        }).catch((err) => {
            console.log(err)
        })
        
        let errors = null

        if (!response.data) {
            errors = {
                status: response.request.status,
                statusText: response.request.statusText
            }
        }

        return {
            data: response.data,
            errors
        }

    },


    changePassword: async ({user,token}) => {
        const response = await api.put(`/${resource}/seguranca/alterar-senha`,{
            ...user
          }, {
            headers: {
                Authorization: token
            } 
        })

        return{
            data: response.data
        }
    },



  
  
})