export default (api, resource) => ({
    
    create: async ({rota, token}) => {

        const response = await api.post(`${resource}`, rota, {
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
        
    },

    getAll: async ({ params, token }) => {

        const response = await api.get(`/${resource}?pagina=${params.pagina}&quantidade=${params.quantidade}`, {
          headers: {
              Authorization: token
            } 
        })
        
        return {
          data: response.data
        }

    },


    update: async ({rota, token}) => {
      
        const response = await api.put(`/${resource}/${rota.id}`, rota, {
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
        
    },
})