import services from '../services'
import { reactive } from 'vue'

export default function () {
    
    const state = reactive({
        cartelas: [],
        pesquisarCartela:'',

        loading:{
           carregarCartela: false
        },
    })

    
    async function carregarCartelas() {

        state.loading.carregarCartela = true

        let token = state.pesquisarCartela

        await services.cartelas.getAll({token})
        .then((response) => {
            state.cartelas = response.data
        })
        .catch((e) => {
           console.log(e)
        })
        .finally(()=>{
            state.loading.carregarCartela = false
        })
    }

    return {
        stateCartelas: state,
        carregarCartelas,
    }
}