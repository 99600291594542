<template>
    <div class="tela-de-catelas">

        <!--TÍTULO-->
        <div class="header flex align-items-center justify-content-between mb-5 p-3" style="background-color: #fff;">
    
            <div class="input-btn flex flex-column align-items-center">
                <h1 class="">CARTELAS</h1>

                <div class="p-inputgroup"> 
                    <InputText v-model="stateCartelas.pesquisarCartela" placeholder="Número da cartela..."/>
                    <Button type="submit" icon="pi pi-search" :loading="stateCartelas.loading.carregarCartela" @click="carregarCartelas()"/>
                </div>

            </div>


            <!--INFORMAÇÕES-->
            <div v-if="stateCartelas.cartelas.games" class="informacoes mb-2 mx-5 flex">
                <div>
                    <div class="font-semibold text-lg">Local: {{ stateCartelas.cartelas.establishment }}</div>
                    <div class="font-semibold text-lg">Data: {{ formatarData(stateCartelas.cartelas.date)}} </div>
                </div>
            
                <div>
                    <div class="font-semibold text-lg">Valor da cartela: {{ formatarParaReais(stateCartelas.cartelas.games[0].paidValue)}} </div>
                    <div class="font-semibold text-lg">Quantidade de cartelas: {{stateCartelas.cartelas.games.length }}</div>
                </div>
                
                <div>
                    <div class="font-semibold text-lg">Sorteio: {{ stateCartelas.cartelas.id }} </div>
                    <div class="font-semibold text-lg">Doado: {{formatarParaReais(stateCartelas.cartelas.games.length * stateCartelas.cartelas.games[0].paidValue)}}</div>
                </div>
            </div>

    
            <div v-else class="informacoes mb-2 mx-5 flex gap-5">
                <div class="font-semibold text-lg">Pesquise por uma cartela.</div>
            </div>

        </div>
            
            
        <!--CARTELA-->
        <div class="cartelas flex mx-4">
            <ul style="padding: 0; margin: 0;" class="flex gap-5" >
                <li  v-for="game in stateCartelas.cartelas.games" :key="game.id" style="list-style-type: none;" class="cartela shadow-2 flex gap-4">
                    
                    <div class="font-bold text-2xl text-center" >Cartela: {{ game.id }}</div>
                    
                    <div class="ordem-numeros">
                        <div class="numero-cartela" v-for="ball in game.ballOfGames" :key="ball.id">{{ ball.number }}</div>
                    </div>

                </li>
            </ul>
        </div>
        
       
    </div>
</template>

<script>
import moment from 'moment'
import { useRoute } from 'vue-router'
import useCartelas from '../../composables/useCartelas'

export default {

    setup(){
        
        const route = useRoute()

        //composables
        const {stateCartelas,carregarCartelas} = useCartelas()


        //pega o token e coloac na rota
        if(route.params.token){
            stateCartelas.pesquisarCartela = route.params.token    
        }
        

        //loads
        carregarCartelas()


        //formata para reais
        function formatarParaReais(valor) {
            return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }


        //formata a data
        function formatarData(data){
            return moment(data, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm")
        }
    
        

        return{
            stateCartelas,
            formatarParaReais,
            carregarCartelas,
            formatarData,
        }
    }
}
</script>


<style scoped>
.ordem-numeros {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 5px;
}

.numero-cartela {
    padding: 10px;
    background-color: #4a4f6d;
    color: white;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    width: 60px;
    height: 60px;
}

.cartela {
    width: 350px; 
    min-height: 300px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: white;
}

.informacoes{
    gap: 15px;
}

@media screen and (max-width: 800px) {

    .header{
        flex-direction: column;
    }

    .input-btn{
        margin-bottom: 32px;
    }

    .informacoes{
        flex-direction: column;
        gap: 0
    }

    .cartelas{
        justify-content: center;
    }

    ul {
        flex-direction: column;
    }
}
</style>