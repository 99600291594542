export default (api, resource) => ({

    getAll: async({token,params}) => {

        const response = await api.get(`${resource}?pagina=${params.pagina}&quantidade=${params.quantidade}`,{
            headers:{
                Authorization: token
            }
        })

        return{
            data: response.data
        }
    },

    
    getState: async({token}) => {

        const response = await api.get(`${resource}/estados`,
            {
            headers:{
                Authorization: token
            }
        })

        return{
            data: response.data
        }
    },

    
    create: async ({section,token}) => { 
        const response = await api.post(resource, section,
        {
            headers:{
                Authorization:token
            }
        })

        return {
          data: response.data
        }
    },


    update: async ({section, token}) => {
        const response = await api.put(`/${resource}/${section.id}`, section,
        {
            headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
    },
})