import axios from 'axios'

import AuthService from './auth'
import UserService from './users'
import RegiaoService from './regioes'
import EstabelecimentoService from './estabelecimentos'
import SecaoService from './secoes'
import RotaService from './rotas'
import PartidaSerivce from './partidas'
import CartelasService from './cartelas'
import SorteioService from './sorteio'
import FinanceiroService from './financeiro'
import RelatorioService from './relatorios'




const ENV = {
  development: process.env.VUE_APP_ROOT_API,
  homologacao: '',
}

const api = axios.create({
  baseURL: ENV[process.env.ENV] || ENV.development
})

export default {
  auth: AuthService(api,'auth'),
  regioes: RegiaoService(api, 'bingo/regiao'),
  users: UserService(api,'bingo/usuario'),
  estabelecimentos: EstabelecimentoService(api,'bingo/estabelecimento'),
  secoes: SecaoService(api,'bingo/secao'),
  rotas: RotaService(api,'bingo/rota'),
  partidas: PartidaSerivce(api,'bingo/partida'),
  cartelas: CartelasService(api,'bingo/jogo'),
  financeiro: FinanceiroService(api,'bingo/movimentacaoFinanceira'),
  sorteio: SorteioService(api,'bingo/sorteio'),
  relatorios: RelatorioService(api,'bingo/relatorio')
}