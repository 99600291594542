export default (api, resource) => ({
    
    create: async ({region, token}) => {

        const response = await api.post(`bingo/regiao`, region, {
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
        
    },

    getAll: async ({ params, token }) => {
	
        const response = await api.get(`/${resource}?pagina=${params.pagina}&quantidade=${params.quantidade}`, {
          headers: {
              Authorization: token
            } 
        })
        
        return {
          data: response.data
        }

    },

    
    update: async ({region, token}) => {
      
        const response = await api.put(`/${resource}/${region.id}`, region, {
          headers: {
              Authorization: token
            } 
        })

        return {
          data: response.data
        }
    },
})