import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () => import('./components/Dashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }],
            requireAuth:true
        },

    },
    {   
        path: '/cartelas',
        name: 'cartelas-pesquisa',
        component: () => import('./pages/Cartelas/Cartelas.vue'),
        meta: {
            breadcrumb: [{ label: 'Cartelas', to : '/cartelas' }],
        },
    },
    {   
        path: `/cartelas/:token`,
        name: 'cartela-detalhes',
        component: () => import('./pages/Cartelas/Cartelas.vue'),
        meta: {
            breadcrumb: [{ label: 'Cartelas', to : '/cartelas' }],
        },
    },
    {
        path: '/relatorios',
        name: 'relatorios',
        exact: true,
        children: [
            {
                path: '/relatorios/recolhe',
                name:'relatorioRecolhe',
                exact: true,
                component: () => import('./pages/Relatorios/Recolhe.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Relatórios', to: '/relatorios/recolhe' },
                        { label: 'Recolhe', to : '/relatorios/recolhe' }
                    ],
                    requireAuth:true
                },
            },
            {
                path: '/relatorios/posicaorecolhe',
                name:'posicaoRecolhe',
                exact: true,
                component: () => import('./pages/Relatorios/PosicaoRecolhe.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Relatórios', to: '/relatorios/posicaorecolhe' },
                        { label: 'Posição Recolhe', to : '/relatorios/posicaorecolhe' }
                    ],
                    requireAuth:true
                },
            },
            {
                path: '/relatorios/ganhadores',
                name:'ganhadores',
                exact: true,
                component: () => import('./pages/Relatorios/Ganhadores.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Relatórios', to: '/relatorios/ganhadores' },
                        { label: 'Ganhadores', to : '/relatorios/ganhadores' }
                    ],
                    requireAuth:true
                },
            },
            {
                path: '/relatorios/sorteio',
                name:'porSorteio',
                exact: true,
                component: () => import('./pages/Relatorios/PorSorteio.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Relatórios', to: '/relatorios/sorteio' },
                        { label: 'Por Sorteio', to : '/relatorios/sorteio' }
                    ],
                    requireAuth:true,
                    requireAdmin: true
                },
            },
            {
                path: '/relatorios/secao',
                name:'porSecao',
                exact: true,
                component: () => import('./pages/Relatorios/PorSecao.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Relatórios', to: '/relatorios/secao' },
                        { label: 'Por Seção', to : '/relatorios/secao' }
                    ],
                    requireAuth:true
                },
            },
            {
                path: '/relatorios/estabelecimento',
                name:'porEstabelecimento',
                exact: true,
                component: () => import('./pages/Relatorios/PorEstabelecimento.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Relatórios', to: '/relatorios/estabelecimento' },
                        { label: 'Por Estabelecimento', to : '/relatorios/estabelecimento' }
                    ],
                    requireAuth:true
                },
            },
        ],
    },
    {
        path: '/usuarios',
        name: 'usuarios',
        exact: true,
        children: [
            {
                path: '/usuarios/admins',
                name:'admins',
                exact: true,
                component: () => import('./pages/Usuarios/Admins.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Usuários', to: '/usuarios/admins' },
                        { label: 'Admins', to : '/usuarios/admins' }
                    ],
                    requireAuth:true
                },
            },
            {
                path: '/usuarios/gestores',
                name:'gestores',
                exact: true,
                component: () => import('./pages/Usuarios/Gestores.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Usuários', to: '/usuarios/gestores' },
                        { label: 'Gestores', to : '/usuarios/gestores' }
                    ],
                    requireAuth:true
                },
            },
            {
                path: '/usuarios/operadores',
                name:'operadores',
                exact: true,
                component: () => import('./pages/Usuarios/Operadores.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Usuários', to: '/usuarios/operadores' },
                        { label: 'Operadores', to : '/usuarios/operadores' }
                    ],
                    requireAuth:true
                },
            },
            {
                path: '/usuarios/tv',
                name:'tv',
                exact: true,
                component: () => import('./pages/Usuarios/Tv.vue'),
                meta: {
                    breadcrumb: [
                        { label: 'Usuários', to: '/usuarios/tv' },
                        { label: 'TV', to : '/usuarios/tv' }
                    ],
                    requireAuth:true
                },
            },
        ],
    },
    {   
        path: '/regioes',
        name: 'regioes',
        exact: true,
        component: () => import('./pages/Regioes/Regioes.vue'),
        meta: {
            breadcrumb: [{ label: 'Regiões', to : '/regioes' }],
            requireAuth:true,
            requireAdmin: true
        },
    },
    {   
        path: '/rotas',
        name: 'rotas',
        exact: true,
        component: () => import('./pages/Rotas/Rotas.vue'),
        meta: {
            breadcrumb: [{ label: 'Rotas', to : '/rotas' }],
            requireAuth:true
        },
    },
    {   
        path: '/secoes',
        name: 'secoes',
        exact: true,
        component: () => import('./pages/Secoes/Secoes.vue'),
        meta: {
            breadcrumb: [{ label: 'Seções', to : '/secoes' }],
            requireAuth:true
        },
    },
    {   
        path: '/estabelecimentos',
        name: 'estabelecimentos',
        exact: true,
        component: () => import('./pages/Estabelecimentos/Estabelecimentos.vue'),
        meta: {
            breadcrumb: [{ label: 'Estabelecimentos', to : '/estabelecimentos' }],
            requireAuth:true
        },
    },
    {   
        path: '/partidas',
        name: 'partidas',
        exact: true,
        component: () => import('./pages/Partidas/Partidas.vue'),
        meta: {
            breadcrumb: [{ label: 'Partidas', to : '/partidas' }],
            requireAuth:true
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 }
    }
})


import store from "./store/store"


router.beforeEach((to, from, next) => {

    const isLogged = store.getters.checkLogged
    const isAdmin = store.getters.isAdmin

    if(to.path == '/' && !isLogged) {
        next('/login')
        return
    }

    if (to.meta.requireAuth) {
        if (!isLogged) {
            next('/login')
        } else if (to.meta.requireAdmin && !isAdmin) {
            next('/login')
        } else {
            next()
        }
    } else {
        next()
    }
})


export default router