export default (api, resource) => ({

    getGanhadores: async ({token,params})=>{
        const response = await api.get(`${resource}/ganhadores?`,{
            params, 
            headers:{
                Authorization : token
            }
        })

        return {
            data: response.data
        }
    },

})