export default (api, resource) => ({


    getAll: async({token})=>{
        const response = await api.get(`${resource}/cartela/${token}`)

        return{
            data: response.data 
        }
    }

})