export default (api, resource) => ({

    getAll: async ({token,rota,params}) => {
        let url = `${resource}/encontrar?funcao=`
        
        if (rota && rota === 'admins'){
            url = `${url}ADMIN&pagina=${params.pagina}&quantidade=${params.quantidade}`
        }

        if(rota && rota === 'gestores'){
            url = `${url}GESTOR&pagina=${params.pagina}&quantidade=${params.quantidade}`
        }

        if(rota && rota === 'operadores'){
            url = `${url}OPERADOR&pagina=${params.pagina}&quantidade=${params.quantidade}`
        }

        if(rota && rota === 'recolhe'){
            url = `${url}RECOLHE&pagina=${params.pagina}&quantidade=${params.quantidade}`
        }

        if(rota && rota === 'tv'){
            url = `${url}TV&pagina=${params.pagina}&quantidade=${params.quantidade}`
        }

        if(rota && rota === 'pos'){
            url = `${url}POS&pagina=${params.pagina}&quantidade=${params.quantidade}`
        }

        
        const response = await api.get(url, {
            headers: {
              Authorization: token
            } 
        })
           
        return {
          data: response.data
        }
    },


    updateUser: async ({user, token}) => {
        
        const response = await api.put(`${resource}/${user.id}/atualizar`, {
            ...user
        },
        {
            headers:{
                Authorization:token
            }
        })

        return {
          data: response.data
        }
    },
})