import { useToast } from "primevue/usetoast"


export default function () {
    const toast = useToast()

    function success (msg = "Sucesso!") {
        toast.add({ severity: 'success', summary: msg, life: 2500 })
    }

    function error (msg = "Tente novamente.") {
        toast.add({ severity: 'error', summary: msg, life: 2500 })
    }

    function info(msg = "!") {
        toast.add({ severity: 'info', summary: msg, life: 2500 })
    }
    
    function warn(msg = "Atenção") {
        toast.add({ severity: 'warn', summary: msg, life: 2500 })
    }

    return {
        toast,
        success,
        error,
        info,
        warn,
    }
}