export default (api, resource) => ({


    getAll: async({token,rota,params})=>{

        let url = `${resource}`

        if(rota && rota === "porSorteio"){
            url = `${url}/por-sorteio`
        }

        if(rota && rota === "porSecao"){
            url = `${url}/por-cidade`
        }

        if(rota && rota === "porEstabelecimento"){
            url = `${url}/por-estabelecimento`
        }

        const response = await api.get( url,{
            params,
            headers:{
                Authorization: token
            }
        })

        return{
            data: response.data 
        }
    },

})